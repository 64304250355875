
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Money from '@/components.global/Money'

export default defineComponent({
  name: 'TheDepositBonus',
  components: { Money, Line, Icon },
})
