
import TheHistoryItem from '@/pages/history/components/TheHistoryItem.vue'
import List from '@/components/AsyncNullableList.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { Order, readHistory } from '@/pages/history/history.api'
import useLoadMore from 'common/hooks/useLoadMore'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import TheDepositBonus from '@/pages/history/components/TheDepositBonus.vue'
import AccountRelated from '@/provider/AccountRelated.vue'

export default defineComponent({
  name: 'History',
  components: {
    AccountRelated,
    TheDepositBonus,
    DemoAccountTip,
    Holder,
    Scroller,
    PageWithHeader,
    TheHistoryItem,
    List,
  },
  setup () {
    const list = shallowRef<Order[]>([])
    // const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    const { loaded, refresh, loadMore } = useLoadMore<Order, any>(
      readHistory, 20, resp => {
        return resp.orders
      },
    )
    watch(() => loaded.value, () => {
      if (loaded.value) {
        list.value = loaded.value.map(item => {
          // if (item.levelType && item.levelType > 0) {
          //   item.leverage = leverage[item.levelType]
          // }
          return item
        })
      }
    })
    refresh()

    return {
      list,
      loaded,
      loadMore,
      showBonus: false,
    }
  },
})
